.price .items {
}

.price {
  margin-top: 50px;
}

.price .grid2 {
  grid-template-columns: repeat(1, 1fr);
}
.primary-btn1 {
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 7px;
  padding-bottom: 10px;
  font-size: small;
  height: 30px;
  text-align: center;
  text-wrap: nowrap;
  color: #ffffff;
  background-color: #234eac;
}

.primary-btn1:hover {
  color: #ffffff;

  background-color: #133172;
}

.price .pricc {
  display: flex;
  flex-direction: row;
}
.price h1 {
  font-size: 50px;
  margin: 20px 0;
  color: #234eac;
}
.price h1 span {
  font-size: 20px;
  margin-right: 5px;
  color: #000;
}
.price p {
  color: grey;
  margin: 40px 0;
}
/*--------------faq-------------*/
.faq .container {
  max-width: 70%;
  border-radius: 5px;
}
.faq .box {
  background-color: #fff;
  margin-bottom: 20px;
}
.faq button {
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 15px 20px;
}
.faq h2 {
  font-weight: 500;
}
.faq p {
  padding: 20px;
}
.faq .accordion:hover {
  background-color: #234eac;
  color: #fff;
}

/*--------------faq-------------*/
/* Add appropriate media queries for responsiveness */
@media screen and (max-width: 768px) {
  .price .items {
    padding: 20px; /* Adjust padding for smaller screens */
  }
  .price .grid2 {
    grid-template-columns: repeat(1, 1fr);
  }
  .price .pricc {
    flex-direction: column; /* Adjust flex direction for smaller screens */
  }

  .price h1 {
    font-size: 40px; /* Adjust font size for smaller screens */
    margin: 15px 0; /* Adjust margin for smaller screens */
  }

  .price h1 span {
    font-size: 16px; /* Adjust font size for smaller screens */
  }

  .price p {
    margin: 20px 0; /* Adjust margin for smaller screens */
  }

  .faq .container {
    max-width: 90%; /* Adjust max-width for smaller screens */
  }
}

@media screen and (max-width: 480px) {
  /* Further adjustments for smaller screens like mobile phones */
  .price .items {
    padding: 10px; /* Adjust padding for smaller screens */
  }

  .price h1 {
    font-size: 30px; /* Adjust font size for smaller screens */
  }

  .price h1 span {
    font-size: 14px; /* Adjust font size for smaller screens */
  }

  .price p {
    margin: 15px 0; /* Adjust margin for smaller screens */
  }

  .faq .container {
    max-width: 100%; /* Adjust max-width for smaller screens */
  }
}

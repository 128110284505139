.aboutHome {
  height: auto; 
  margin-top: 5%;
}
.Listnames {
  background-color: #0554ff1a; /* Change to your desired background color */
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 60px;
  padding-left: 5%;
}
.aboutHome .text p {
  line-height: 38px;
}

.Listnames .MuiListItem-root {
  font-size: 20px; /* Change to your desired font size */
  align-items: center;
}

.team .grid2 {
  grid-template-columns: repeat(1, 1fr);
}
.flexS {
  display: flex;
  align-items: center;
}
.aboutHome #heading {
  text-align: left;
  padding: 0;
}
.aboutHome .left img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.aboutHome .right {
  padding: 0px 50px;
  padding-bottom: 50px;
  padding-top: 0px;
  padding-right: 0%;
  margin-right: 0px;
}
.aboutHome .items {
  margin-top: 0px;
  align-items: center;
}
.aboutHome .item {
  background-color: #fff;
  margin-top: 30px;
  padding: 20px;
  transition: 0.5s;
}
.aboutHome .img {
  width: 200px;
  padding-right: 60px;
  padding-left: 30px;
}
.aboutHome img {
  width: 70px;
  height: 70px;
}
.aboutHome .item h2 {
  font-size: 20px;
  margin-bottom: 15px;
}
.aboutHome .item p {
  color: #999999;
}
.aboutHome .item:hover {
  background-color: #234eac;
  color: #fff;
  cursor: pointer;
  box-shadow: 0 5px 25px -2px rgb(0 0 0 / 6%);
}
.aboutHome .item:hover p {
  color: #fff;
}
/*----------awrapper------------*/
.awrapper {
  background-image: url("../../../public/images/awrapper.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 30vh;
  color: #fff;
}
.awrapper .box {
  padding: 70px 0;
}
.awrapper img {
  margin-right: 30px;
}
.awrapper h1 {
  font-size: 50px;
}
.awrapper h3 {
  font-size: 20px;
  font-weight: 500;
}
/*----------awrapper------------*/
@media screen and (max-width: 768px) {
  .aboutHome {
    margin-top: 5%;
  }
  .aboutHome .container {
    flex-direction: column;
  }
  .aboutHome .row {
    width: 100%;
    padding: 0%;
    flex-direction: row;
  }
  .flexS {
    flex-direction: column;
  }
  .right .text {
    padding-left: 0px;
  }

  .right img {
    display: none;
  }
  .aboutHome img {
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .awrapper {
    height: 50vh;
    margin-top: 25px;
  }
  .awrapper .box {
    margin-top: 35px;
    padding: 12px 0;
  }
}

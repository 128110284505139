.team .items {
  transition: 0.5s;
}
.team .img {
  position: relative;
}
.team img {
  width: 100%;
  height: 100%;
}

.items .img {
  width: auto;
}
.team .container {
  flex-direction: column;
}
.overlay {
  position: absolute;
  bottom: 0;
  z-index: 11;
  display: flex;
  flex-direction: column;
  padding: 20px;
  opacity: 0;
  transition: 0.5s;
}
.overlay i {
  background-color: #000;
  color: #fff;
  margin: 5px;
  transition: 0.5s;
}
.team .details {
  padding: 30px;
  text-align: center;
}
.team .details h2 {
  font-weight: 500;
  font-size: 20px;
  transition: 0.5s;
}
.team .details p {
  font-size: 15px;
  color: grey;
  margin-top: 10px;
  transition: 0.5s;
}
.team .items:hover {
  background-color: #234eac;
  color: rgb(255, 255, 255);
  cursor: pointer;
}

.team .grid2:hover .items p {
  background-color: #ffffff;
  color: grey;
}

.team .items:hover .desc {
  display: flex;
}
.team .items:hover p {
  color: rgb(255, 255, 255);
}
.team .items:hover .overlay {
  opacity: 1;
}
.team .items:hover .overlay i:hover {
  background-color: #234eac;
  color: #fff;
}

/* Add appropriate media queries for responsiveness */
@media screen and (max-width: 768px) {
  .team .details {
    padding: 15px; /* Adjust padding for smaller screens */
  }

  .team .details h2 {
    font-size: 18px; /* Adjust font size for smaller screens */
  }

  .team .details p {
    font-size: 14px; /* Adjust font size for smaller screens */
  }

  .overlay {
    padding: 10px; /* Adjust padding for smaller screens */
  }

  .overlay i {
    margin: 2px; /* Adjust margin for smaller screens */
  }
}

@media screen and (max-width: 480px) {
  /* Further adjustments for smaller screens like mobile phones */
  .team .details {
    padding: 10px; /* Adjust padding for smaller screens */
  }

  .team .details h2 {
    font-size: 16px; /* Adjust font size for smaller screens */
  }

  .team .details p {
    font-size: 12px; /* Adjust font size for smaller screens */
  }

  .overlay {
    padding: 8px; /* Adjust padding for smaller screens */
  }

  .overlay i {
    margin: 1px; /* Adjust margin for smaller screens */
  }
}

.coursesCard {
  padding-top: 20px;
  padding-left: 0%;
  width: 100%;
  margin-bottom: 50px;
}
.coursesCard p {
  color: #898f8f;
  line-height: 40px;
  padding-left: 1%;
  width: 100%;
}
.coursesCard .items {
  background-color: #fff;
  text-align: center;
  width: 100%;
}
.coursesCard .img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: #234eac;
  padding: 15px;
}
.coursesCard img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.coursesCard .text {
  margin-left: 15px;
  text-align: left;
}
.coursesCard .text h1 {
  font-size: 30px;
  font-weight: 500;
  line-height: 45px;
}
.coursesCard .rate {
  margin: 20px 0;
  color: #234eac;
}
.coursesCard .rate i {
  font-size: 13px;
  margin-right: 5px;
}
.coursesCard .details .dimg img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 20px;
}

.bb #heading h1 {
  font-size: 45px;
  margin: 20px 0;
  text-transform: capitalize;
  text-align: center;
}
.coursesCard .details .box {
  display: flex;
  align-items: center;
  color: grey;
}

.coursesCard .grid2 {
  grid-template-columns: repeat(1, 1fr);
}
.coursesCard .details span {
  color: #234eac;
  font-weight: 500;
  font-size: 14px;
}
.coursesCard .price {
  margin: 30px 0;
  background-color: #f8f8f8;
  padding: 10px;
}
.coursesCard h3 {
  font-weight: 500;
  color: #234eac;
}
/*-------------online---------*/
.online {
  text-align: center;
}
.online .box {
  box-shadow: 0 5px 25px -2px rgb(0 0 0 / 6%);
  background-color: #fff;
  padding: 30px 20px;
  transition: 0.5s;
}
.online .img {
  width: 80px;
  height: 80px;
  margin: auto;
  position: relative;
}
.online img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.online h1 {
  font-weight: 500;
  font-size: 20px;
  margin: 20px 0;
  line-height: 30px;
}
.online span {
  background-color: #f8f8f8;
  padding: 5px 20px;
  font-weight: 500;
  font-size: 15px;
  color: #234eac;
  border-radius: 5px;
}
.online .box .show {
  opacity: 0;
}
.online .box:hover {
  background-color: #234eac;
  border-radius: 5px;
  cursor: pointer;
}
.online .box:hover .show {
  opacity: 1;
  position: absolute;
  top: 0;
  left: 0;
}
.online .box:hover h1 {
  color: #fff;
}
/*-------------online---------*/
/* Add appropriate media queries for responsiveness */
@media screen and (max-width: 768px) {
  .coursesCard {
    width: 100%; /* Adjust width to fit smaller screens */
  }
  .coursesCard .grid2 {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 30px;
  }
  .coursesCard .items {
    width: 100%; /* Adjust width to fit smaller screens */
  }

  .coursesCard .text h1 {
    font-size: 24px; /* Adjust font size for smaller screens */
    line-height: 40px; /* Adjust line height for smaller screens */
  }

  .coursesCard .rate {
    margin: 15px 0; /* Adjust margin for smaller screens */
  }

  .coursesCard .rate i {
    font-size: 12px; /* Adjust font size for smaller screens */
  }

  .coursesCard .details .dimg img {
    width: 40px; /* Adjust image width for smaller screens */
    height: 40px; /* Adjust image height for smaller screens */
    margin-right: 10px; /* Adjust margin for smaller screens */
  }

  .coursesCard .details span {
    font-size: 12px; /* Adjust font size for smaller screens */
  }

  .coursesCard p {
    font-size: 17px;
    line-height: 34px;
  }

  .coursesCard .price {
    margin: 20px 0; /* Adjust margin for smaller screens */
  }

  .coursesCard h3 {
    font-size: 22px; /* Adjust font size for smaller screens */
  }

  /* Styles for .online section */
  .online .box {
    padding: 20px; /* Adjust padding for smaller screens */
  }

  .online h1 {
    font-size: 18px; /* Adjust font size for smaller screens */
    margin: 15px 0; /* Adjust margin for smaller screens */
    line-height: 25px; /* Adjust line height for smaller screens */
  }

  .online span {
    padding: 3px 15px; /* Adjust padding for smaller screens */
    font-size: 13px; /* Adjust font size for smaller screens */
  }

  .online .img {
    width: 60px; /* Adjust image width for smaller screens */
    height: 60px; /* Adjust image height for smaller screens */
  }
}

.goToTopButton {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #234eac;
  color: #fff;
  border: none;
  border-radius: 50%; /* Making the button circular */
  width: 50px; /* Adjust width as needed */
  height: 50px; /* Adjust height as needed */
  padding: 0; /* Remove padding to ensure button is perfectly circular */
  cursor: pointer;
  z-index: 999;
  display: none;
}

.goToTopButton i {
  padding-right: 40%;
}

.goToTopButton.visible {
  display: block; /* Show the button when visible */
}

.goToTopButton:hover {
  background-color: #1a3c85;
  color: #ffffff; /* Darker background color on hover */
}

@media screen and (max-width: 768px) {
  .goToTopButton i {
    padding-right: 20%;
  }
}

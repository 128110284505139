.hero {
  position: relative; /* Ensure relative positioning */
  background-image: url("../../../../public/images/bg.webp");
  background-size: cover;
  background-attachment: fixed;
  top: 0;
  left: 0;
  z-index: 0; /* Lower z-index to ensure content appears above */
  width: 100%;
  height: 80vh;
  padding-top: 10%;
  padding-right: 50px;
  color: #ffffff;
}

.hero::before {
  content: ""; /* Required for pseudo-elements */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Black with 50% opacity */
  z-index: -1; /* Ensure the pseudo-element stays behind the content */
}

.hero #heading h3 {
  color: #fff;
}

.hero #heading {
  text-align: left;
  margin: 0;
  padding: 0;
}

@media screen and (max-width: 768px) {
  .hero {
    background-position: center; /* Adjust background position */
    padding-top: 20%; /* Adjust padding top */
    height: 70vh; /* Adjust height */
    padding-right: 0;
    color: aliceblue; /* Remove right padding */
  }

  .hero .row {
    width: 100%; /* Adjust width */
  }
  .hero .container {
    font-size: large;
  }
  .hero #heading {
    text-align: center; /* Center align heading */
  }

  .hero p {
    font-size: medium;
    text-align: center;
  }

  .margin {
    height: 40vh; /* Adjust margin height */
  }

  .hero #heading h3 {
    color: #fff; /* Change color of heading */
  }
  .row .button {
    text-align: center;
    padding-left: 0%;
    flex-direction: column;
  }
}

@media screen and (max-width: 480px) {
  /* Further adjustments for smaller screens like mobile phones */
  .hero {
    padding-top: 20%; /* Adjust padding top */
    height: 35rem; /* Adjust height */
  }

  .margin {
    height: 30vh; /* Adjust margin height */
  }
}

/*--------head------------*/
.head {
  padding: 20px 0;
  padding-left: 5px;
  color: #2e0f77;
}

.logo h1 {
  font-size: 35px;
  font-weight: 900;
}

.logo img {
  height: 75px;
}

.head .icon {
  margin-left: 10px;
}
/*--------head------------*/

/*--------header------------*/

header {
  background-color: #234eac7e;
  margin: 0 0px;
  padding-left: 0px;
}

header .nav {
  display: flex !important;
}

header li {
  margin-right: 40px;
}

header ul {
  padding: 30px 20px 30px 0px;
}

header ul li a {
  color: #fff;
  font-weight: 500;
  transition: 0.5s;
}

header ul li a:hover {
  color: #1a3c86;
}

.start {
  background-color: #234eac;
  padding: 30px 70px;
  color: #fff;
  clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
}

.toggle {
  display: none;
}

.logoo {
  height: 80px;
}
/*--------header------------*/

@media screen and (max-width: 768px) {
  .start {
    clip-path: none;
    padding: 20px; /* Adjust padding for mobile */
  }

  header {
    margin: 0;
    background-color: #234eac;
    position: relative;
  }

  .head .container {
    padding-left: 0px;
    padding-right: 0px;
  }

  header ul.flexSB {
    display: none;
  }

  header ul li {
    margin-bottom: 20px;
  }

  .flexSB {
    justify-content: space-between;
    align-items: center;
  }
  .logo img {
    height: 75px;
  }

  .head .icon {
    width: 35px;
    height: 35px;
    line-height: 35px;
    border-radius: 50%;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.3);
    color: #ffffff;
    transition: 0.5s;
  }

  .toggle {
    display: block;
    background: none;
    color: #fff;
    font-size: 30px;
    position: absolute;
    right: 0;
    top: -20px;
    padding: 10px;
  }

  .mobile-nav {
    position: absolute;
    top: 7vh;
    left: 0;
    width: 100%;
    background-color: #234eac;
    padding-top: 10px;
    padding-left: 13%;
    z-index: 2; /* Adjust padding for mobile */
  }
}

@media screen and (max-width: 396px) {
  .logo img {
    height: 57px;
  }
}

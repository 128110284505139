#heading h3 {
  font-weight: 600;
  letter-spacing: 1px;
  color: #234eac;
  text-transform: uppercase;
}
#heading h1 {
  font-size: 35px;
  margin: 20px 0;
  font-weight: 600;
  text-transform: capitalize;
}

#heading p {
  font-size: 25px;
}
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 222.2 84% 4.9%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;

    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;

    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;

    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;

    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;

    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;

    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 212.7 26.8% 83.9%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap");
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
body {
  font-family: "Roboto", sans-serif;
  background-color: #f8f8f8;
}
/*------------global-----------*/
.container {
  max-width: 85%;
  margin: auto;
  margin-bottom: 0px;
}
.details .desc {
  display: none;
}
.heading {
  margin-bottom: 0px;
}
.flexSB {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.icon {
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.3);
  color: #ffffff;
  transition: 0.5s;
}
.icon:hover {
  cursor: pointer;
  background-color: #234eac;
  color: #fff;
}
a {
  text-decoration: none;
}
li {
  list-style-type: none;
}
.row {
  width: 60%;
}
.row1 {
  width: 40%;
}

button {
  padding: 17px 30px;
  background-color: #fff;
  color: #234eac;
  font-weight: 600;
  border: none;
  margin: 30px 10px 0 0;
  border-radius: 3px;
  cursor: pointer;
  box-shadow: 0 24px 36px -11px rgb(0 0 0 / 9%);
  transition: 0.5s;
}
button i {
  margin-left: 20px;
}
button:hover {
  box-shadow: none;
}
.primary-btn {
  background-color: #234eac;
  color: #fff;
}

/*------------global-----------*/
#heading {
  text-align: center;
  padding: 40px 0;
}
#heading h3 {
  font-weight: 600;
  letter-spacing: 1px;
  color: #234eac;
  text-transform: uppercase;
}
#heading h1 {
  font-size: 45px;
  margin: 20px 0;
  text-transform: none;
}
p {
  line-height: 30px;
  font-size: 18px;
}

.back {
  background-image: url("../public/images/back.webp");
  background-size: cover;
  background-attachment: fixed;
  /*position: absolute;*/
  top: 0;
  left: 0;
  z-index: -7;
  width: 100%;
  height: 60vh;
  padding-top: 10%;
  padding-right: 50px;
  color: #fff;
  text-align: center;
}

.back h1 {
  font-size: 100px;
  font-weight: 400;
}
.back h2 {
  font-weight: 500;
  font-size: 17px;
  text-transform: uppercase;
}
.margin {
  margin-top: 40.3%;
}
.grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 30px;
}
.flex {
  display: flex;
}
.grid2 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
}

.grid4 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
}
.outline-btn {
  margin: 0;
  box-shadow: none;
  border: 2px solid #234eac;
  width: 100%;
  transition: 0.5s;
}
.outline-btn:hover {
  background-color: #234eac;
  color: #fff;
}
.grid3 {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 10px;
}
.padding {
  padding: 20px 0;
}
.shadow {
  box-shadow: 0 5px 25px -2px rgb(0 0 0 / 6%);
  background-color: #fff;
}

/* Add appropriate media queries for responsiveness */
@media screen and (max-width: 1024px) {
  .container {
    max-width: 90%;
  }
}

@media screen and (max-width: 768px) {
  /* Adjustments for tablet screens and smaller */
  button {
    padding: 10px 20px; /* Further reduced padding for smaller screens */
    font-size: 12px; /* Further reduced font size */
    margin: 15px 5px 0 0;
    /* Adjusted margin */
  }

  .flexSBD {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
  }

  .row .button {
    padding-left: 35px;
    margin-bottom: 40px;
    align-items: center;
  }

  button i {
    margin-left: 8px; /* Further reduced icon margin */
  }
  #heading h3 {
    font-weight: 600;
    letter-spacing: 1px;
    color: #234eac;
    text-transform: uppercase;
  }
  #heading h1 {
    font-size: 32px;
    margin: 20px 0;
    font-weight: 600;

    text-transform: capitalize;
  }

  #heading p {
    font-size: 25px;
  }
  .container {
    max-width: 95%;
  }

  .heading h1 {
    font-size: 5px;
    line-height: 0%;
  }

  .back {
    padding-top: 25%;
    padding-right: 5%;
    padding-left: 5%;
  }

  .back h1 {
    font-size: 70px;
  }

  .grid,
  .grid2,
  .grid3,
  .grid6 {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
  }

  .grid4 {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 20px;
    row-gap: 20px;
  }
  .awrapper .grid {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
    row-gap: 0px;
  }
  .row,
  .row1 {
    width: 100%;
  }

  .margin {
    margin-top: 10%;
  }
  .awrapper .flex {
    padding: 0px;
  }
}

@media screen and (max-width: 480px) {
  /* Adjustments for mobile phones */
  .container {
    max-width: 100%;
  }

  #heading h1 {
    font-size: 27px;
    margin: 20px 0;
    font-weight: 600;
    text-transform: capitalize;
  }
  button {
    padding: 10px 20px; /* Further reduced padding for smaller screens */
    font-size: 12px; /* Further reduced font size */
    margin: 15px 5px 0 0;
    /* Adjusted margin */
  }
  .heading h1 {
    font-size: 10px;
  }
  .back h1 {
    font-size: 60px; /* Adjust font size for smaller screens */
    text-align: center;
  }

  .back h2 {
    font-weight: 500;
    font-size: 14px; /* Adjust font size for smaller screens */
    text-transform: uppercase;
  }
  .back {
    padding-top: 50%;
    text-align: center;
  }

  .grid,
  .grid2,
  .grid3 {
    grid-template-columns: 1fr;
  }
  .grid4 {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 20px;
    row-gap: 50px;
  }

  .awrapper .grid {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0px;
    row-gap: 0px;
  }

  .row,
  .row1 {
    width: 100%;
  }
  .awrapper .flex {
    padding: 0px;
  }
  .margin {
    margin-top: 15%;
  }
}

/*@media screen and (max-width: 768px) {
  .grid2,
  .grid3,
  .grid {
    grid-template-columns: repeat(2, 1fr);
  }
  .back {
    background-position: center;
    padding-top: 50%;
    height: 80vh;
  }
}*/

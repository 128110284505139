.testimonal .items {
  padding: 30px;
  transition: 0.5s;
}
.container{
  
}
.testimonal .img {
  position: relative;
}


.testimonal img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}
.testimonal i {
  position: absolute;
  bottom: 0;
  left: 60px;
  background-color: #234eac;
  color: #fff;
}
.testimonal .name {
  margin: 25px;
  margin-left: 5%;
}
.testimonal .name h2 {
  font-size: 20px;
  margin-bottom: 5px;
}
.testimonal .name span {
  color: #234eac;
}
.testimonal p {
  margin-top: 20px;
  color: grey;
  transition: 0.5s;
}
.testimonal .items:hover {
  background-color: #234eac;
  color: #fff;
  cursor: pointer;
}
.testimonal .items:hover span,
.testimonal .items:hover p {
  color: #fff;
}
.testimonal .items:hover i {
  background-color: #fff;
  color: #234eac;
}
/* Add appropriate media queries for responsiveness */
@media screen and (max-width: 768px) {
  .testimonal .items {
    padding: 20px; /* Adjust padding for smaller screens */
  }

  .testimonal img {
    width: 80px; /* Adjust image width for smaller screens */
    height: 80px; /* Adjust image height for smaller screens */
  }

  .testimonal i {
    left: 50%; /* Center the icon horizontally */
    transform: translateX(-50%); /* Center the icon horizontally */
  }

  .testimonal .name h2 {
    font-size: 18px; /* Adjust font size for smaller screens */
  }

  .testimonal p {
    margin-top: 15px; /* Adjust margin for smaller screens */
  }
}

@media screen and (max-width: 480px) {
  /* Further adjustments for smaller screens like mobile phones */
  .testimonal .items {
    padding: 15px; /* Adjust padding for smaller screens */
  }

  .testimonal img {
    width: 60px; /* Adjust image width for smaller screens */
    height: 60px; /* Adjust image height for smaller screens */
  }

  .testimonal .name h2 {
    font-size: 16px; /* Adjust font size for smaller screens */
  }

  .testimonal p {
    margin-top: 10px; /* Adjust margin for smaller screens */
  }
}

.newletter {
  background-color: #234eac;
  padding: 50px 0;
  color: #fff;
}
.newletter .left {
  padding-right: 50px;
}

.newletter .right button {
  padding: 0px;
  background-color: #ffffff;
  color: #ffffff;
  font-weight: 600;
  border: none;
  margin: 30px 0px 0 0;
  border-radius: 3px;
  cursor: pointer;
  box-shadow: 0 24px 36px -11px rgb(0 0 0 / 9%);
  transition: 0.5s;
}
.newsLetter .right {
  padding-left: 0%;
}
footer {
  padding-top: 3%;
}
.newletter .name {
  margin-bottom: 20px;
  color: rgb(0, 0, 0);
}

.newletter .right {
  display: grid;
}
.newletter h1 {
  color: #fff;
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 15px;
}
.newletter .right {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.newletter input {
  width: 100%;
  padding: 20px;
  border: none;
  outline: none;
  height: 30px;
  color: black;
}
.newletter i {
  padding: 12px;
  margin: 0%;
  color: #234eac;
  background-color: #fff;
}

.last li {
  align-items: center;
}
footer {
  background-color: #eeeeee;
}
.last .fa-map {
  padding-right: 37.8px;
}
footer .container {
  display: grid;
  grid-template-columns: 3fr 2fr 2fr 3fr;
  grid-gap: 30px;
}
/* Update appropriate media queries for responsiveness */
@media screen and (max-width: 768px) {
  footer .container {
    grid-template-columns: 1fr; /* Change grid template columns to one column for smaller screens */
    grid-gap: 20px; /* Adjust grid gap for smaller screens */
  }
}

footer .logo span {
  color: #234eac;
  font-size: 14px;
}
footer .logo p {
  color: grey;
  margin: 30px 0 15px 0;
}
footer .logo .icon {
  background-color: #234eac;
  color: white;
  margin-right: 10px;
}
footer h3 {
  margin-bottom: 40px;
  font-weight: 500;
}
footer ul li {
  margin-bottom: 20px;
}
footer .link li::before {
  content: "⟶";
  color: #234eac;
  margin-right: 5px;
}
footer .items {
  margin-bottom: 30px;
}
footer .items img {
  width: 50px;
  height: 50px;
  border-radius: 5px;
  margin-right: 10px;
  object-fit: cover;
}
footer .items span,
footer .items i {
  font-size: 12px;
  color: #234eac;
  margin-right: 5px;
  text-transform: capitalize;
}
footer h4 {
  font-weight: 400;
  margin-top: 5px;
}
footer .last ul li {
  display: flex;
  margin-bottom: 50px;
}
footer .last i {
  width: 60px;
  font-size: 20px;
  color: #234eac;
}
.legal {
  text-align: center;
  padding: 50px 0;
  color: grey;
  background-color: #e9e9e9;
}
.legal p {
  font-size: 15px;
}
.legal i {
  color: #234eac;
}
/* Add appropriate media queries for responsiveness */
@media screen and (max-width: 768px) {
  .newletter {
    padding: 30px 0;
    /* Adjust padding for smaller screens */
  }
  .newletter .flexSB {
    flex-direction: column;
    column-gap: 10px;
  }
  .newletter .right,
  .newletter .left {
    padding-right: 10px;
    margin-top: 10px; /* Remove right padding for smaller screens */
  }

  .newletter h1 {
    font-size: 24px; /* Adjust font size for smaller screens */
    margin-bottom: 10px; /* Adjust margin for smaller screens */
  }

  .newletter input {
    padding: 15px; /* Adjust padding for smaller screens */
  }

  .footer .container {
    grid-template-columns: repeat(
      1,
      1fr
    ); /* Change grid template columns to one column for smaller screens */
    grid-gap: 20px;
    /* Adjust grid gap for smaller screens */
  }
  footer .container {
    padding-left: 35px;
  }

  .grid6 {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
  }

  .footer h3 {
    margin-bottom: 30px; /* Adjust margin for smaller screens */
  }

  .footer ul li {
    margin-bottom: 15px; /* Adjust margin for smaller screens */
  }

  .footer .items img {
    width: 40px; /* Adjust image width for smaller screens */
    height: 40px; /* Adjust image height for smaller screens */
    margin-right: 5px; /* Adjust margin for smaller screens */
  }

  .footer .last ul li {
    margin-bottom: 30px; /* Adjust margin for smaller screens */
  }

  .legal {
    padding: 30px 0; /* Adjust padding for smaller screens */
  }

  .legal p {
    font-size: 14px; /* Adjust font size for smaller screens */
  }
}

@media screen and (max-width: 480px) {
  /* Further adjustments for smaller screens like mobile phones */
  .newletter h1 {
    font-size: 20px; /* Adjust font size for smaller screens */
  }

  .newletter input {
    padding: 10px; /* Adjust padding for smaller screens */
  }

  .footer .items img {
    width: 30px; /* Adjust image width for smaller screens */
    height: 30px; /* Adjust image height for smaller screens */
  }

  .footer .last ul li {
    margin-bottom: 20px; /* Adjust margin for smaller screens */
  }

  .legal p {
    font-size: 13px; /* Adjust font size for smaller screens */
  }
}
